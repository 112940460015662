<template>
  <router-link to="/pos-data" custom v-slot="{ navigate }" class="w-100 h-100">
    <b-container class="menu-container">
      <b-col class="h-100 w-100 border border-dark rounded pos-col">
        <b-row class="no-gutters main-row">
          <b-col>
            <b-row class="justify-content-center">
              <b class="menu-title">Position</b>
            </b-row>
            <b-row class="justify-content-center">
              <b-form-checkbox
                class="live-cb"
                id="cbLive"
                :disabled="cbLiveDisable"
                v-model="cbLiveState"
                name="cbLive"
                @change="onCbLiveChange">
                Live
              </b-form-checkbox>
            </b-row>
            <b-row class="justify-content-center">
              <div class="movement-div">
                <b-form-checkbox
                  id="cbRoll"
                  :disabled="cbLiveDisable"
                  v-model="cbEnableRoll"
                  name="cbRoll">
                  R
                </b-form-checkbox>
                &emsp;&emsp;
                <b-form-checkbox
                  class="movement-cb"
                  id="cbPitch"
                  :disabled="cbLiveDisable"
                  v-model="cbEnablePitch"
                  name="cbPitch">
                  P &emsp;
                </b-form-checkbox>
                &emsp;&emsp;
                <b-form-checkbox
                  class="movement-cb"
                  id="cbYaw"
                  :disabled="cbLiveDisable"
                  v-model="cbEnableYaw"
                  name="cbYaw">
                  Y &emsp;
                </b-form-checkbox>
              </div>
            </b-row>
            <b-row class="justify-content-center">
              <div class="model-container">
                <model-stl 
                  src="static/models/stl/Kapsel_zentrum.STL" 
                  :controlsOptions="modelControls" 
                  :backgroundColor="modelBgColor"  
                  :rotation="modelRotation"
                  :cameraRotation="modelCamRotation"
                  :cameraPosition="modelCamPosition"
                  :scale="modelScale"
                  :width="modelWidth"
                  :height="modelHeight"
                  @on-load="modelOnLoad">
                </model-stl>
              </div>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="button-row no-gutters">
          <b-button class="graph-button" role="link" @click="handler => showGraphView(handler, navigate)">Show graphs</b-button>
        </b-row>
      </b-col>
    </b-container>
  </router-link>
</template>

<script>
import {ModelStl} from 'vue-3d-model'

export default {
  name: "MenuPosition",
  components: {
    ModelStl,
  },

  data() {
    return {
      cbLiveState: false,
      cbEnableRoll: false,
      cbEnablePitch: false,
      cbEnableYaw: true,

      modelBgColor: '#8c8c8c',
      
      // modelRotation: {
      //   x: -Math.PI/2 - 0.4, //Math.PI/2,
      //   y: 0.4, //-Math.PI/2,
      //   z: 0, //Math.PI/2,
      // },
      modelRotation: {
        x: -Math.PI/2,
        y: 0,
        z: 0,
      },

      /* Does nothing */
      modelCamRotation: {
        x: 0,
        y: 0,
        z: 0
      },

      modelCamPosition: {
        x: 0,
        y: 0,
        z: 0
      },

      modelControls: {
        enableZoom: false,
        enableRotate: false,
        enablePan: false,
      },

      modelScale: {
        x: 0.65,
        y: 0.65,
        z: 0.65
      },

      q0: 1,
      q1: 0,
      q2: 0, 
      q3: 0,
      q4: 0,

      xFilter: 0,
      yFilter: 0,
      zFilter: 0,

      lastImuUpdate: null
    }
  },

  computed: {
    cbLiveDisable: function() {
      return !this.$store.state.bleConnected
      //return true  // /* !!!!!!!!! */
    },
    liveRotationEnabled: function() {
      return this.cbLiveState && this.$store.state.bleConnected
    },
    modelHeight: function() {
      return window.innerHeight / 3.5
    },
    modelWidth: function() {
      //return window.innerWidth/2.2
      return this.modelHeight * 0.74509803921
    },
  },

/*  mounted() {
    console.log("modelWidth = " + this.modelWidth)
    console.log("modelHeight = " + this.modelHeight)
  },*/

  methods: {
    showGraphView(handler, navigate) {
      if(this.$store.state.sMotionAvailable) {
        console.log("Navigating to position graph")
        return navigate(handler)
      }

      console.log("Service motion not available!")
      return undefined
    },

    async onCbLiveChange(checked) {
      if(checked && this.$store.state.bleConnected) {
        await this.$store.state.motHandles.acc.startNotifications()
        await this.$store.state.motHandles.gyr.startNotifications()
      }else {
        await this.$store.state.motHandles.acc.stopNotifications()
        await this.$store.state.motHandles.gyr.stopNotifications()

        this.modelRotation.x = -Math.PI/2 - 0.4
        this.modelRotation.y = 0.4
        this.modelRotation.z = 0
      }
    },

    modelOnLoad() {
      this.modelRotate()
    },
    modelRotate() {
      /*************************************************/
      /*      Could use more testing v
      /*************************************************/
      if(this.liveRotationEnabled) {
        let angles = this.updateIMU()

        if(angles != null) {
          if(this.cbEnableRoll === true) {
            this.modelRotation.x = 2 * Math.PI + angles.roll
          }

          if(this.cbEnablePitch === true) {
            this.modelRotation.y = 2 * Math.PI + (2 * angles.pitch) //angles.pitch - Math.PI/2
          }

          if(this.cbEnableYaw === true) {
            this.modelRotation.z = 2 * Math.PI - (4 * angles.yaw)
          }

          //console.log('pitch: ' + angles.pitch + ' yaw: ' + angles.yaw + ' roll: ' + angles.roll)
        }
      }else {
        this.modelRotation.z += 0.01
      }

      requestAnimationFrame(this.modelRotate)
    },

    /* It works but is very sensitive and jumps around a lot */
    updateIMUv2() {
      const alpha = 0.5

      let accData = this.$store.getters.getLastAccData
      if(accData == null) return null

      this.xFilter = accData.x * alpha + (this.xFilter * (1.0 - alpha))
      this.yFilter = accData.y * alpha + (this.yFilter * (1.0 - alpha))
      this.zFilter = accData.z * alpha + (this.zFilter * (1.0 - alpha))

      let r = (Math.atan2(-this.yFilter, this.zFilter) * 180.0) / Math.PI
      let p = (Math.atan2(this.xFilter, Math.sqrt(this.yFilter * this.yFilter + this.zFilter * this.zFilter)) * 180.0) / Math.PI

      return {roll: r, pitch: p}
    },

    updateIMU() {
      const twoKp = (2 * 0.5) // 2 * proportional gain

      let accData = this.$store.getters.getLastAccData
      if(accData == null) return null

      let gyrData = this.$store.getters.getLastGyrData
      if(gyrData == null) return null

      // Convert gyroscope data drom deg/s to rad/s
      gyrData.x *= 0.01745333
      gyrData.y *= 0.01745333
      gyrData.z *= 0.01745333

      let now = Date.now()
      let deltaT = ((now - this.lastImuUpdate) / 1000.0)
      this.lastImuUpdate = now;

      let normFactor = this.inverseSqrt(accData.x * accData.x + accData.y * accData.y + accData.z * accData.z)
      accData.x *= normFactor
      accData.y *= normFactor
      accData.z *= normFactor

      let halfVx = this.q1 * this.q3 - this.q0 * this.q2
      let halfVy = this.q0 * this.q1 - this.q2 * this.q3
      let halfVz = this.q0 * this.q0 - 0.5 + this.q3 * this.q3

      let halfEx = accData.y * halfVz - accData.z * halfVy
      let halfEy = accData.z * halfVx - accData.x * halfVz
      let halfEz = accData.x * halfVy - accData.y * halfVx

      gyrData.x += (twoKp * halfEx)
      gyrData.y += (twoKp * halfEy)
      gyrData.z += (twoKp * halfEz)

      gyrData.x *= (0.5 * deltaT)
      gyrData.y *= (0.5 * deltaT)
      gyrData.z *= (0.5 * deltaT)

      let qa = this.q0, qb = this.q1, qc = this.q2

      this.q0 += (-qb * gyrData.x - qc * gyrData.y - this.q3 * gyrData.z)
      this.q1 += (qa * gyrData.x + qc * gyrData.z - this.q3 * gyrData.y)
      this.q2 += (qa * gyrData.y - qb * gyrData.z + this.q3 * gyrData.x)
      this.q3 += (qa * gyrData.z + qb * gyrData.y - qc * gyrData.x)

      normFactor = this.inverseSqrt(this.q0 * this.q0 + this.q1 * this.q1 + this.q2 * this.q2 + this.q3 * this.q3)
      this.q0 *= normFactor
      this.q1 *= normFactor
      this.q2 *= normFactor
      this.q3 *= normFactor

      let r = Math.atan2(this.q0 * this.q1 + this.q2 * this.q3, 0.5 - this.q1 * this.q1 - this.q2 * this.q2)
      let p = Math.asin(-2.0 * (this.q1 * this.q3 - this.q0 * this.q2))
      let y = Math.atan2(this.q1 * this.q2 + this.q0 * this.q3, 0.5 - this.q2 * this.q2 - this.q3 * this.q3)

      // Convert back to degrees, this makes everything jumpy
      // r = r * (180/Math.PI)
      // p = p * (180/Math.PI)
      // y = y * (180/Math.PI)

      return {roll: r, pitch: p, yaw: y}
    },

    inverseSqrt(x) {
      const m = 0x5F375A86
      let buf = new ArrayBuffer(4)
      let view = new DataView(buf)

      let f
      let xHalf = x * 0.5
      let th = 1.5

      view.setFloat32(0, x)
      view.setUint32(0, m - (view.getUint32(0) >> 1))
      f = view.getFloat32(0)
      f *= (th - (xHalf * f * f))
      f *= (th - (xHalf * f * f))

      return f
    }
  },
}

</script>

<style scoped>
.pos-col {
  padding: 0;
  background-color: #8c8c8c;
}
.menu-container {
  padding: 0;
  font-size: 14px;
}
.menu-title {
  font-size: 16px;
}
.model-container {
  display:  grid;
  /*height: 100%;*/
  width: 100%;
  align-content: center;
  /*padding-top: 10%;*/
}

.movement-div {
  display: flex;
}

.movement-cb {
  display: flex;
  margin-left: 20px;
}

.live-cb {
  margin-left: 7%;
}

.main-row {
  height: 90%;
}
.button-row {
  height: 10%;
}
.graph-button {
  margin-left: 10px;
  font-size: 14px;
  height: 35px;
  background-color: #2c3e50;
}

</style>