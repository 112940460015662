<template>          
  <router-link to="/bioz-data" custom v-slot="{ navigate }" class="h-100 w-100 border border-dark rounded bg-color">
    <!--<div role="link" @click="handler => showGraphView(handler, navigate)">-->
    <div role="link" @click="navigate">
      <b-row>
        <b class="menu-title">Bio impedance</b>
      </b-row>
      <b-row class="w-100 icon-container">
        <bio-icon :size="30" class="material-icons-comb" />
        <alpha-z-box-icon :size="30" class="material-icons-comb" />&nbsp;
        <omega-icon :size="30" class="material-icons" />
      </b-row>
    </div>
  </router-link>
</template>

<script>
import BioIcon from 'vue-material-design-icons/Bio.vue';
import AlphaZBoxIcon from 'vue-material-design-icons/AlphaZBox.vue';
import OmegaIcon from 'vue-material-design-icons/Omega.vue';

export default {
  name: "MenuBioz",
  components: {
    BioIcon,
    AlphaZBoxIcon,
    OmegaIcon,
  },

  methods: {
    showGraphView(handler, navigate) {
      if(this.$store.state.sImpedanceAvailable) {
        console.log("Navigating to impedance graph")
        return navigate(handler)
      }

      console.log("Impedance service not available!")
      return undefined
    }
  }  
}
  
</script>

<style scoped>
.bg-color {
  background-color: #8c8c8c;
}
.menu-title {
  font-size: 16px;
  margin-left: 10%;
}
.icon-container {
  padding-left: 15%;
  padding-top: 12%;
  align-items: center;
}
.material-icons {
  align-text: left;
  margin-right: 20px;
}
.material-icons-comb {
  vertical-align: top;
  padding-right: 0px;
  margin-right:  -3px;
}

</style>