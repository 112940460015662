<template>
  <div class="battery">
    <div class="top"></div>
    <div class="outer">
      <div
        v-for="n in 5"
        :key="n.id"
        class="inner"
        :class="{ hidden: n <= 5 - charge }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BatteryIndicator",
  props: ['charge'],

  data () {
    return {
    }
  },
}
  

</script>


<style>
.battery {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top {
  background: #000;
  width: 20px;
  height: 10px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 2px;
}

.outer {
  width: 40px;
  border: 5px solid #000;
  height: 80px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.inner {
  /*background: #f00;*/
  background: blue;
  flex: 1;
  margin: 2px;
  border-radius: 5px;
}

.inner.hidden {
  visibility: hidden;
}
</style>