<template>
  <div class="home-div">
    <b-container class="home-container">
      <b-row class="h-50 no-gutters">
        <b-col class="w-50 h-100 no-gutters">
          <menu-position />
        </b-col>
        <b-col class="w-50 h-100 no-gutters">
          <b-row class="h-75 no-gutters">
            <menu-mic />
          </b-row>
          <b-row class="h-25 no-gutters">
            <menu-bluetooth />
          </b-row>
        </b-col>
      </b-row>
      <b-row class="h-50 no-gutters">
        <b-col class="w-50 h-100 no-pad">
          <b-row class="h-25 no-gutters">
            <menu-bioz />
          </b-row>
          <b-row class="h-75 no-gutters">
            <menu-sensors />
          </b-row>
        </b-col>
        <b-col class="w-50 h-100 no pad no-gutters">
          <b-row class="h-25 no-gutters">
            <menu-battery />
          </b-row>
          <b-row class="h-75 no-gutters">
            <menu-lights />
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <menu-bioz-detector />
  </div>
</template>

<script>

import MenuPosition from '../views/MenuPosition.vue'
import MenuMic from '../views/MenuMic.vue'
import MenuBluetooth from '../views/MenuBluetooth.vue'
import MenuSensors from '../views/MenuSensors.vue'
import MenuBattery from '../views/MenuBattery.vue'
import MenuLights from '../views/MenuLights.vue'
import MenuBioz from "../views/MenuBioz.vue"
import MenuBiozDetector from '../views/MenuBiozDetector.vue'

export default {
  name: "AppLayoutHome",
  components: {
    MenuPosition,
    MenuMic,
    MenuBluetooth,
    MenuSensors,
    MenuBattery,
    MenuLights,
    MenuBioz,
    MenuBiozDetector,
  },

  data() {
    return {
    }
  },

  methods: {

  },
}
</script>

<style scoped>
.home-div {
  height: 100%;
  overflow: hidden;
  /*max-height: 860px;*/
}
.home-container {
  height: 95%;
  /*width: 100vw;*/
  padding: 5px;
}

.no-marg {
  margin: 0px;
}

.no-pad {
  padding: 0px;
}
.no-pad-right {
  padding-right: 0px;
}
.no-pad-left {
  padding-left: 0px;
}
</style>