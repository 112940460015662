<template>
  <router-link to="/liquid-data" custom v-slot="{ navigate }" class="rounded">
    <div class="outer-div">
      <div class="circle-div">
        <div class="front btn-icon" role="link" @click="handler => showGraphView(handler, navigate)"></div>
      </div>
    </div>
  </router-link>  
  <!--
  <router-link to="/liquid-data" custom v-slot="{ navigate }" class="h-100 w-100 border border-dark rounded bg-color">
    <span role="link" @click="navigate">
      <div class="root-div">
        <div class="container-div">
          <div class="flip-container" :class="doFlip && flipStyle">
            <b-overlay :show="showSpinOverlay" rounded="pill" class="overlay-style">
              <div class="circle-div" align-v="center">
                <div class="flipper">
                  <div class="front btn-icon"></div>
                </div>
              </div>
            </b-overlay> 
          </div>
        </div>
      </div>
    </span>
  </router-link>  
  -->
  <!--
  <div class="root-div">
    <div class="container-div">
      <div class="flip-container" :class="doFlip && flipStyle">
        <b-overlay :show="showSpinOverlay" rounded="pill" class="overlay-style">
          <div class="circle-div" align-v="center">
            <div class="flipper" @click="buttonHandler">
              <div class="front btn-icon">
              </div>
              <div class="back">
                <glass-mug-variant-icon class="liquid-icon" v-if="liquidClass == 1" /> 
                <cup-water-icon class="liquid-icon" v-if="liquidClass == 2" />
                <glass-wine-icon class="liquid-icon" v-if="liquidClass == 3" />
                <coffee-maker-icon class="liquid-icon" v-if="liquidClass == 4" />
                <fruit-watermelon-icon class="liquid-icon" v-if="liquidClass == 5" />
              </div>
            </div>
          </div>
        </b-overlay> 
      </div>
    </div>
  </div>
  -->
</template>


<script>
/*
import GlassMugVariantIcon from 'vue-material-design-icons/GlassMugVariant.vue';
import CupWaterIcon from 'vue-material-design-icons/CupWater.vue';
import GlassWineIcon from 'vue-material-design-icons/GlassWine.vue';
import CoffeeMakerIcon from 'vue-material-design-icons/CoffeeMaker.vue';
import FruitWatermelonIcon from 'vue-material-design-icons/FruitWatermelon.vue';
*/

export default {
  name: "MenuBiozDetector",
  components: {
    /*
    GlassMugVariantIcon,
    CupWaterIcon,
    GlassWineIcon,
    CoffeeMakerIcon,
    FruitWatermelonIcon
    */
  },
  data() {
    return {
      doFlip: false,
      flipStyle: 'hover',

      simTimer: null,

      liquidClass: 0,


    }
  },
  computed: {
    showSpinOverlay() {
      return this.$store.state.bleConnecting
    }
  },

  methods: {
    showGraphView(handler, navigate) {
      /*
      if(this.$store.state.sImpedanceAvailable) {
        console.log("Navigating to impedance graph")
        return navigate(handler)
      }

      console.log("Impedance service not available!")
      return undefined
      */

      return navigate(handler)
    },

    classifyLiquid () {
      if(this.doFlip) {
        this.flip()
      }else {
        this.showSpinOverlay = true
        this.simTimer = setInterval(this.flip, 2000)
      }
    },
    flip () {
      if(!this.doFlip) {
        clearInterval(this.simTimer)
        this.showSpinOverlay = false
        this.getRandomLiquid()
      }

      this.doFlip = !(this.doFlip)
    },
    getRandomLiquid () {
      this.liquidClass = Math.floor(Math.random() * (5 - 1 + 1)) + 1
      //console.log(this.liquidClass)
    },

    buttonHandler() {
      //this.classifyLiquid()
      // if(this.bleConnected) {
      //   this.classifyLiquid()
      // }
    },
  }
}

</script>

<style scoped>
.outer-div {
  display: table;
  position: absolute;
  left: calc(50% - 80px);
  bottom: calc(50% - 50px);
}
.circle-div {
  height: 100px;
  width: 160px;
  border-radius: 50px;
  border: 2px solid #2c3e50;
  background-color: white;
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

/* hide back of pane during swap */
.front {
  /*backface-visibility: hidden;*/
  position: absolute;
  top: 0;
  left: 0;
}

.btn-icon {
  height: 54px;
  width: 114px;
  top: calc(50% - 27px); /* Minus half height */
  left: calc(50% - 57px);
  background-image: url(../static/MST_Logo_sm.png);
}
</style>